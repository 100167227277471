import Application from "@ember/application";
import * as Sentry from "@sentry/ember";
import runtimeEnvironment from "core/utils/runtime-environment";
import loadInitializers from "ember-load-initializers";
import Resolver from "ember-resolver";
import ENV from "parog-web/config/environment";
import "./deprecation-workflow";

if (ENV["@sentry/ember"].sentry.dsn) {
  const sentryConfiguration = Object.assign(ENV["@sentry/ember"].sentry, {
    environment: runtimeEnvironment(),
    normalizeDepth: 11
  });

  const disabledEnvs = ["production", "preproduction"];
  if (!disabledEnvs.includes(runtimeEnvironment())) {
    // Enable Sentry Replay, only on non-production environments.
    sentryConfiguration.integrations = [Sentry.replayIntegration()];
    sentryConfiguration.replaysSessionSampleRate = 0.1;
    sentryConfiguration.replaysOnErrorSampleRate = 1.0;
  }

  Sentry.init(sentryConfiguration);
}

export default class App extends Application {
  modulePrefix = ENV.modulePrefix;
  podModulePrefix = ENV.podModulePrefix;
  Resolver = Resolver;

  engines = {};
}

loadInitializers(App, ENV.modulePrefix);
