import { service } from "@ember/service";
import NativeAppService from "core/services/native-app";
import SemeiaSessionService from "core/services/session";
import Base from "ember-simple-auth/authenticators/base";
import { SessionRestoreError } from "parog-web/authenticators/utils/errors";
import { verifyJWT } from "parog-web/authenticators/utils/jwt";
import { resetSentryUser, setPatientSentryUser } from "./utils/sentry";

export default class PatientImplicitAuthenticator extends Base {
  @service declare session: SemeiaSessionService;
  @service declare nativeApp: NativeAppService;
  @service requestManager;

  async restore(data) {
    try {
      verifyJWT(data.access_token);
      this.verifyAuthenticatedData(data);
    } catch (e) {
      resetSentryUser();
      throw new SessionRestoreError({ cause: e });
    }

    setPatientSentryUser(data);
    // FIXME misses patientLoggedIn?
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async authenticate(requestData) {
    // If the patient is already authenticated, keep him logged in
    // with the previous access token if the new one is already expired

    // FIXME actually, this code will be called if a link with an access token has been opened.
    //   Here, we must check the identity contained in the token, and logout if different.
    //   Otherwise, use the token if it is valid, or ignore the authenticate and resolve otherwise
    //   (we keep the active session active).

    // TODO we should check for validity of stored token before finalizing login process.
    // This code is weird, it will pass if the token is invalid.
    // But this is currently expected: the workflow continues if the given token
    // is invalid, but the local one is still good.
    let requestJWTIsInvalid = false;
    try {
      verifyJWT(requestData.access_token);
    } catch {
      requestJWTIsInvalid = true;
    }
    if (
      this.session.isAuthenticated &&
      this.session.data.authenticated.as === "patient" &&
      requestJWTIsInvalid
    ) {
      try {
        verifyJWT(this.session.data.authenticated.access_token);
        this.verifyAuthenticatedData(this.session.data.authenticated);
      } catch (e) {
        throw new SessionRestoreError({ cause: e });
      }

      this.nativeApp.patientLoggedIn();
      return this.session.data.authenticated;
    }

    let responseBody;

    try {
      const { content } = await this.requestManager.request({
        url: "/api/v1/authorize_token",
        method: "POST",
        body: JSON.stringify(requestData)
      });

      responseBody = content;
    } catch ({ response, content }) {
      if (!response.ok) throw content;
    }

    const data = {
      as: "patient",
      id: responseBody.patient_id,
      uuid: responseBody.patient_uuid,
      health_centre_id: responseBody.patient_health_centre_id,
      access_token: responseBody.jwt
    };

    setPatientSentryUser(data);
    this.nativeApp.patientLoggedIn();

    this.session.authenticatedAtThisSession = true;
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async invalidate() {
    resetSentryUser();
  }

  private verifyAuthenticatedData(data: any) {
    if (!["patient", "medic"].includes(data.as)) {
      throw "Invalid data property: as";
    }

    if (!data.id) {
      throw "Missing data property: id";
    }
  }
}
