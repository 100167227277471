import { cancel, Timer } from "@ember/runloop";
import { service } from "@ember/service";
import SemeiaSession from "core/services/session";
import Base from "ember-simple-auth/authenticators/base";
import {
  callRefreshTokenEndpoint,
  isTokenValid,
  scheduleAccessTokenRefresh,
  updateSessionAccessToken
} from "./utils/refresh-access-tokens";
import { revokeToken } from "./utils/revoke-token";
import {
  resetSentryUser,
  setMedicSentryUser,
  setPatientSentryUser
} from "./utils/sentry";

interface UserAuthData {
  as: string;
  id: number | string;
  uuid: string;

  access_token: string;
  refresh_token?: string;
  two_factor_auth_token?: string;
}

export interface MedicAuthData extends UserAuthData {
  role: string;
  sees_all_centre_patients?: boolean;
  is_super_user?: boolean;

  browser_auth_token?: string;
  id_token?: string;
}

export interface PatientAuthData extends UserAuthData {
  health_centre_id: number;
}

type AuthData = MedicAuthData | PatientAuthData;

export default abstract class BaseAuthenticator<
  T extends AuthData
> extends Base {
  @service declare session: SemeiaSession;
  @service requestManager;

  abstract loginEndpoint: string;
  abstract userClassName: "Medic" | "Patient";

  declare _refreshTokenTimeout?: Timer;

  async restore(data: T): Promise<T> {
    if (data.refresh_token) {
      // Do not authenticate if refresh token is expired
      if (!isTokenValid(data.refresh_token)) {
        resetSentryUser();
        throw "Base Password Authenticator - Invalid refresh token";
      }

      // If access token is expired, refresh it
      if (!isTokenValid(data.access_token)) {
        const { response } = await callRefreshTokenEndpoint.call(
          this,
          data.refresh_token
        );
        data.access_token = response.jwt;

        updateSessionAccessToken.call(this, data.access_token);
      }

      // Otherwise, do nothing and schedule next access token refresh
      scheduleAccessTokenRefresh.call(this, data);
    }

    if (this.userClassName == "Medic") {
      setMedicSentryUser(data as MedicAuthData);
    } else if (this.userClassName == "Patient") {
      setPatientSentryUser(data as PatientAuthData);
    }

    await this.session.fetchAuthenticatedUser(data);

    return data;
  }

  abstract authenticate(args: unknown): Promise<T | undefined>;

  async invalidate(): Promise<void> {
    if (this.session.user?.refreshTokensEnabled) {
      revokeToken.call(this);
    }
    cancel(this._refreshTokenTimeout);
    delete this._refreshTokenTimeout;
    resetSentryUser();
  }

  protected async sendLoginRequest(authData: any): Promise<T> {
    const body = JSON.stringify({
      auth: authData
    });

    try {
      const { content } = await this.requestManager.request({
        url: this.loginEndpoint,
        method: "POST",
        body: body
      });

      return content;
    } catch ({ response, content }) {
      if (!response.ok) throw response;
      return content;
    }
  }

  protected async onAuthenticationFinished(
    data: T | undefined
  ): Promise<T | undefined> {
    if (data) {
      if (this.userClassName == "Medic") {
        setMedicSentryUser(data as MedicAuthData);
      } else if (this.userClassName == "Patient") {
        setPatientSentryUser(data as PatientAuthData);
      }
    } else {
      resetSentryUser();
    }

    this.session.authenticatedAtThisSession = true;
    if (data !== undefined) {
      await this.session.fetchAuthenticatedUser(data);
    }
    if (this.session.user?.refreshTokensEnabled) {
      scheduleAccessTokenRefresh.call(this, data);
    }
    return data;
  }
}
