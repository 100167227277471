export default {
  time: {
    hhmmss: {
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    },
    hhmm: {
      hour: "numeric",
      minute: "numeric"
    }
  },
  date: {
    hhmmss: {
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    },
    "DD MMMM YYYY": {
      day: "numeric",
      month: "long",
      year: "numeric"
    },
    "DD MMMM": {
      day: "numeric",
      month: "long"
    },
    "DD/MM/YYYY": {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    },
    "DD/MM": {
      day: "2-digit",
      month: "2-digit"
    },
    "DD/MM/YY": {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    }
  },
  number: {
    compact: { notation: "compact" },
    EUR: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    USD: {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }
};
