import Controller from "@ember/controller";
import { action } from "@ember/object";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class extends Controller {
  @service declare router: RouterService;
  @action
  transitionToIndex(): void {
    this.router.transitionTo("index");
  }
}
