import ApplicationInstance from "@ember/application/instance";
import { getOwner } from "@ember/owner";
import AuthUtils from "core/utils/authentication";
import ApplicationAdapter from "parog-web/adapters/application";

export async function revokeToken(this): Promise<void> {
  const applicationAdapter = (getOwner(this) as ApplicationInstance).lookup(
    "adapter:application"
  ) as ApplicationAdapter;

  const refreshToken = AuthUtils.getRefreshTokenFromStorage();
  if (refreshToken) {
    const body = JSON.stringify({
      refresh_token: refreshToken
    });

    await fetch("/api/v1/revoke_token", {
      method: "POST",
      headers: Object.assign(
        {
          "Content-Type": "application/json"
        },
        applicationAdapter.headers as unknown as HeadersInit
      ),
      body: body
    });
  }
}
