import { service } from "@ember/service";
import * as Sentry from "@sentry/ember";
import SemeiaSessionService from "core/services/session";
import Base from "ember-simple-auth/authenticators/base";
import { MedicAuthData } from "parog-web/authenticators/base";
import {
  resetSentryUser,
  setMedicSentryUser
} from "parog-web/authenticators/utils/sentry";

export default class MedicImplicitAuthenticator extends Base {
  @service declare session: SemeiaSessionService;
  @service requestManager;

  async restore(data) {
    if (!this._isTokenValid(data.access_token)) {
      resetSentryUser();
      throw "Medic Implicit Authenticator - Invalid token";
    }

    setMedicSentryUser(data);

    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async authenticate(requestData) {
    // If the medic is already authenticated, keep him logged in
    // with the previous access token if the new one is already expired
    if (
      this.session.isAuthenticated &&
      this.session.data.authenticated.as === "medic" &&
      !this._isTokenValid(requestData.access_token)
    ) {
      return this.session.data.authenticated;
    }

    await this.requestManager.request({
      url: "/api/v1/authorize_medic_token",
      method: "POST",
      body: JSON.stringify(requestData)
    });

    const data = this._extractDataFromToken(requestData.access_token);
    if (!data) return;
    setMedicSentryUser(data);

    this.session.authenticatedAtThisSession = true;
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async invalidate() {
    resetSentryUser();
  }

  _isTokenValid(token) {
    try {
      const payload = this._decodeToken(token);
      const expirationTimestamp = payload.exp * 1000;
      const todayTimestamp = new Date().getTime();

      return todayTimestamp < expirationTimestamp;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  }

  _extractDataFromToken(token): MedicAuthData | undefined {
    try {
      const payload = this._decodeToken(token);
      return {
        id: payload.sub as string,
        uuid: payload.uuid as string,
        // FIXME role is actually not set in the token
        //  See Tokens::MedicAuth.from_medic.
        //
        // FIXME when setting up Sentry context, there will
        //  be missing information, expected by users of Sentry.
        //  For instance, role, is_super_user are not present.
        role: payload.role as string,
        as: "medic",
        access_token: token
      };
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }

  _decodeToken(token) {
    return JSON.parse(window.atob(token.split(".")[1]));
  }
}
