import AuthUtils from "core/utils/authentication";
import BaseAuthenticator, { PatientAuthData } from "./base";

export default class PatientsPasswordAuthenticator extends BaseAuthenticator<PatientAuthData> {
  loginEndpoint = "/api/v1/patient_login";
  userClassName: "Medic" | "Patient" = "Patient";

  async authenticate({
    email,
    password,
    twoFactorCode,
    twoFactorToken
  }: {
    email: string;
    password: string;
    twoFactorCode?: string;
    twoFactorToken?: string;
  }): Promise<PatientAuthData | undefined> {
    let data;

    try {
      const response = await this.sendLoginRequest({
        email,
        password,
        two_factor_code: twoFactorCode,
        two_factor_token: twoFactorToken
      });

      data = {
        as: "patient",
        id: response.id,
        uuid: response.uuid,
        health_centre_id: response.health_centre_id,
        access_token: response.access_token,
        refresh_token: response.refresh_token
      };

      const newTwoFactorToken = response.two_factor_auth_token;
      if (newTwoFactorToken) {
        AuthUtils.save2faTokenInStorage(email, newTwoFactorToken);
      }
    } catch (e) {
      throw {
        reason: e
      };
    }

    return this.onAuthenticationFinished(data);
  }
}
