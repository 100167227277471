import Route from "@ember/routing/route";
import { service } from "@ember/service";
import OncoToulouseInfoMessage from "core/models/onco-toulouse-info-message";
import FetcherService from "core/services/fetcher";

export default class TermsOfUseRoute extends Route {
  @service declare fetcher: FetcherService;

  async model(): Promise<OncoToulouseInfoMessage | undefined> {
    const oncoToulouseInfoMessages = await this.fetcher.loadRecords(
      "onco-toulouse-info-message"
    );
    if (oncoToulouseInfoMessages.length == 0) {
      return undefined;
    }

    return oncoToulouseInfoMessages.slice(-1)[0];
  }
}
