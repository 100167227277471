import * as Sentry from "@sentry/ember";
import { MedicAuthData, PatientAuthData } from "parog-web/authenticators/base";

interface MedicsWebSentryUser extends Sentry.User {
  id: string | number;
  uuid: string;
}

interface MedicSentryUser extends MedicsWebSentryUser {
  class: "Medic";
  role: string;
  sees_all_centre_patients?: string;
  is_super_user?: string;
}

interface PatientSentryUser extends MedicsWebSentryUser {
  class: "Patient";
  health_centre_id: string;
}

export function setMedicSentryUser(authData: MedicAuthData) {
  const scope = Sentry.getCurrentScope();
  // If updating this code, update back/app/controllers/concerns/semeia_sentry.rb also
  // so that they are in sync and thus there will be the same information in back and front transactions.
  const sentryUser: MedicSentryUser = {
    id: `Medic-${authData.id}`,
    uuid: authData.uuid,
    class: "Medic",
    role: authData.role,
    sees_all_centre_patients: authData.sees_all_centre_patients?.toString(),
    is_super_user: authData.is_super_user?.toString()
  };

  scope.setUser(sentryUser);
}

export function setPatientSentryUser(authData: PatientAuthData) {
  const scope = Sentry.getCurrentScope();
  // If updating this code, update back/app/controllers/concerns/semeia_sentry.rb also
  // so that they are in sync and thus there will be the same information in back and front transactions.
  const sentryUser: PatientSentryUser = {
    id: `Patient-${authData.id}`,
    uuid: authData.uuid,
    class: "Patient",
    health_centre_id: authData.health_centre_id?.toString()
  };

  scope.setUser(sentryUser);
}

export function resetSentryUser() {
  const scope = Sentry.getCurrentScope();
  scope.setUser(null);
}
