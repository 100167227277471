export class InvalidJWTError extends Error {
  cause: Error | undefined;

  constructor(
    public jwt: string,
    options?: { cause?: Error }
  ) {
    super(undefined);
    this.cause = options?.cause;
  }
}

export class ExpiredJWTError extends Error {
  constructor(
    public jwt: string,
    public timestamp: number
  ) {
    super();
  }
}

/**
 * Verifies the JWT and throws errors if not valid or expired.
 */
export function verifyJWT(jwt: string) {
  let payload: { exp: number };
  try {
    payload = JSON.parse(atob(jwt.split(".")[1]));
  } catch (e) {
    throw new InvalidJWTError(jwt, { cause: e });
  }
  const expirationTimestamp = payload.exp * 1000;
  const todayTimestamp = new Date().getTime();

  const isExpired = todayTimestamp > expirationTimestamp;
  if (isExpired) {
    throw new ExpiredJWTError(jwt, expirationTimestamp);
  }
}
