/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { type ComponentLike } from "@glint/template";
import MedicalHistoryCardio from "core/components/medical-history/cardio";
import MedicalHistoryDiabeto from "core/components/medical-history/diabeto";
import MedicalHistoryDigest from "core/components/medical-history/digest";
import MedicalHistoryGeneric from "core/components/medical-history/generic";
import MedicalHistoryHepato from "core/components/medical-history/hepato";
import MedicalHistoryMental from "core/components/medical-history/mental";
import MedicalHistoryNephro from "core/components/medical-history/nephro";
import MedicalHistoryOnco from "core/components/medical-history/onco";
import MedicalHistoryPneumo from "core/components/medical-history/pneumo";
import MedicalHistoryRhumato from "core/components/medical-history/rhumato";
import type Patient from "core/models/patient";
interface Signature {
  Args: {
    patient: Patient;
    sourceJeId: string;
    isReadOnly: boolean;
    afterCompleted: () => void;
    afterCancel: () => void;
    formAction: string;
  };
}

const AreaComponents = {
  cardio: MedicalHistoryCardio,
  diabeto: MedicalHistoryDiabeto,
  digest: MedicalHistoryDigest,
  generic: MedicalHistoryGeneric,
  hepato: MedicalHistoryHepato,
  mental: MedicalHistoryMental,
  nephro: MedicalHistoryNephro,
  onco: MedicalHistoryOnco,
  pneumo: MedicalHistoryPneumo,
  rhumato: MedicalHistoryRhumato
};

export default class MedicalHistoryComponent extends Component<Signature> {
  get areaComponent(): ComponentLike<Signature> | undefined {
    if (!this.args.patient.followUpReasonRecord) return;

    const area = this.args.patient.followUpReasonRecord.therapeuticalArea.id;
    return AreaComponents[area];
  }
}
