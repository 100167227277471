import Route from "@ember/routing/route";

class Model {
  save() {
    // do nothing
  }
}

export default class extends Route {
  model(): Model {
    return new Model();
  }
}
