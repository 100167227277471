import Route from "@ember/routing/route";
import RouteInfo from "@ember/routing/route-info";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import SemeiaSessionService from "core/services/session";

export default class OpenIdLoginRoute extends Route {
  @service declare session: SemeiaSessionService;
  @service declare router: RouterService;

  async beforeModel(transition: Transition) {
    const params = (transition.to as RouteInfo).queryParams;

    if (this.session.isAuthenticated || !params.code) {
      this.router.transitionTo("medics-web.logout");
    }
  }
}
