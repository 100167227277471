import { action } from "@ember/object";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import AnalyticsService from "core/services/analytics";
import NativeAppService from "core/services/native-app";
import { IntlService } from "ember-intl";
import SessionService from "ember-simple-auth/services/session";

export default class ApplicationRoute extends Route {
  @service declare intl: IntlService;
  @service declare analytics: AnalyticsService;
  @service declare nativeApp: NativeAppService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  constructor(...args: ConstructorParameters<typeof Route>) {
    super(...args);
    this.analytics.startRouterTracking();
  }

  async beforeModel() {
    await this.session.setup();
    this.intl.setLocale("fr-fr");

    this.nativeApp.setup();
  }

  @action
  // @ts-expect-error: error return type incoherent with ember documentation
  error(error: any) {
    if (error.errors && ["401", "403"].includes(error.errors[0].status)) {
      this.router.replaceWith("medics-web.login");
    } else {
      // Let the route above this handle the error.
      return true;
    }
  }
}
