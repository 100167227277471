import SurveyRequestedJournalEvent from "core/models/survey-requested-journal-event";

export function surveyRequestedTransitionToParams(
  je: SurveyRequestedJournalEvent,
  authenticatedAs: string,
  unauthorizedRoute: string,
  callbackRoute: string,
  formRequestedRoute: string
): [route: string, ...models: any[]] {
  if (!je?.isVisibleBy(authenticatedAs)) return [unauthorizedRoute];
  if (je.isCompleted || je.isExpired) return [callbackRoute];

  return [`${formRequestedRoute}.feature.component`, ...je.formId.split("/")];
}
