import BaseAuthenticator, { MedicAuthData } from "./base";

interface OpenIdAuthArgs {
  authorizationCode: string;
}

interface OpenIdAuthData extends MedicAuthData {
  id_token: string;
}

export default class OpenIdConnectAuthenticator extends BaseAuthenticator<OpenIdAuthData> {
  loginEndpoint = "/api/v1/medic_login";
  userClassName: "Medic" | "Patient" = "Medic";

  async authenticate({
    authorizationCode
  }: OpenIdAuthArgs): Promise<OpenIdAuthData | undefined> {
    let data;
    try {
      const response = await this.sendLoginRequest({
        psc_authorization_code: authorizationCode
      });

      data = {
        as: "medic",
        id: response.id,
        uuid: response.uuid,
        role: response.role,
        sees_all_centre_patients: response.sees_all_centre_patients,
        is_super_user: response.is_super_user,
        access_token: response.access_token,
        id_token: response.id_token
      };
    } catch (response) {
      throw {
        reason: response
      };
    }

    return this.onAuthenticationFinished(data);
  }
}
